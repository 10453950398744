import React, { useState, useCallback } from 'react';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { Buffer } from 'buffer';
import './App.css';


const TOKEN_PRICE_SOL = 0.0000142; // Example token price
const PRESALE_CONTRACT = '5LXiVsXiakanGTR1tcA6sCjfXPpVnmAGrLFB5yhEmTBc'; // Presale contract address

const FlafPresale = () => {
  const { connection } = useConnection();
  const { publicKey, signTransaction } = useWallet();
  const [solAmount, setSolAmount] = useState('');
  const [flafAmount, setFlafAmount] = useState('');


  // Function to handle buying tokens
  const buyTokens = useCallback(async () => {
    const solAmountNumber = parseFloat(solAmount);
    if (isNaN(solAmountNumber) || solAmountNumber < 0.1) {
      alert('Please enter a valid amount of SOL.');
      return;
    }
  
    const flafAmount = solAmountNumber / TOKEN_PRICE_SOL;
    const userConsent = window.confirm(`You are about to purchase ${flafAmount.toFixed(4)} $FLAF for ${solAmountNumber.toFixed(4)} $SOL...`);
  
    if (!userConsent) {
      console.log('User canceled the transaction.');
      return;
    }
  
    if (!publicKey || !signTransaction) {
      alert('Please connect your wallet.');
      return;
    }
  
    try {
      const { blockhash } = await connection.getLatestBlockhash();
      let transaction = new Transaction({
        recentBlockhash: blockhash,
        feePayer: publicKey,
      });
  
      transaction.add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey(PRESALE_CONTRACT),
          lamports: solAmountNumber * LAMPORTS_PER_SOL,
        })
      );
  
      const signed = await signTransaction(transaction);
      const signature = await connection.sendRawTransaction(signed.serialize());
  
      // Updated confirmation method
      await connection.confirmTransaction({ signature, blockhash }, "confirmed");
  
      console.log('Transaction signature:', signature);
      alert('Transaction successful!');
    } catch (error) {
      console.error('Transaction error:', error);
      alert(`Transaction failed: ${error.message}`);
    }
  }, [publicKey, signTransaction, solAmount, connection]);
  

  // Function to calculate FLAF amount based on SOL input
  const handleSolAmountChange = (e) => {
    const sol = parseFloat(e.target.value);
    if (!isNaN(sol)) {
      setSolAmount(sol.toString());
      setFlafAmount((sol / TOKEN_PRICE_SOL).toFixed(4));
    }
  };

  console.log(Buffer.isBuffer);
  return (
    <div id="flaf-token-presale">
      <div id="left-column">
        <h1 className="ph1">Fluffy Alpaca Finance ($FLAF) Token Presale</h1>
        <div id="progress-bar-container">
          <div id="progress-bar">
            <div id="progress"></div>
          </div>
          <span id="sol-raised"># SOL raised</span>
          <span id="sol-target">300 SOL target</span>
        </div><br/>
        <div id="input-container">
          <div className="input-box" id="pay-box">
            <div>
              <label>You pay:</label>
              <span className="usd-cost">USD COST</span>
            </div>
            <div>
              <span className="usd-value">$0.00</span>
            </div>
            <div>
              <label className="currency">$SOL</label>
              <input
                type="text"
                id="sol-amount"
                placeholder="0.0000"
                pattern="[0-9.]*"
                value={solAmount}
                onChange={handleSolAmountChange}
              />
            </div>
          </div>

          <div className="input-box" id="receive-box">
            <div>
              <label>You receive:</label>
            </div>
            <div>
              <label className="currency">$FLAF</label>
              <input
                type="text"
                id="flaf-amount"
                placeholder="0.0000"
                value={flafAmount}
                readOnly
              />
            </div>
          </div>
        </div>
        <button id="buy-tokens" onClick={buyTokens}>Buy Token</button>
        <div id="wallet-status">{publicKey ? `Wallet connected: ${publicKey.toString().slice(0, 4)}...${publicKey.toString().slice(-4)}` : 'No wallet connected'}</div>
      </div>
      <WalletMultiButton />
    </div>
  );
};

export default FlafPresale;


